import { getTestByTestCode } from './tests';

import TestConfig from '../../components/common/data/TestConfig';
import { parseThresholdAsZscore } from './PrepareTestForDisplay';

const NORMALIZED_DIFF_ZSCORE = -1.5;
const NORMALIZED_PATHO_ZSCORE = -2.0;

function getThresholds(testConfig, { pathoThresholdAsZscore, difficultyThresholdAsZscore }) {
  if (testConfig && (testConfig.patho || testConfig.difficulty)) {
    const pathoConfig = parseThresholdAsZscore(testConfig.patho, -1.6449);
    const difficultyConfig = parseThresholdAsZscore(testConfig.difficulty, -1.3);
    return { pathoThresholdAsZscore: pathoConfig, difficultyThresholdAsZscore: difficultyConfig };
  }
  return { pathoThresholdAsZscore, difficultyThresholdAsZscore };
}

const getNormalizedZScore: (string, number) => Promise<number> = async (testName, zscore) => {
  // FIXME the next 3 lines should be called only once per test display and not on every testFillingRow call
  const test = await getTestByTestCode(testName);
  const testConfig = await TestConfig.findByTestName(testName);
  const { pathoThresholdAsZscore, difficultyThresholdAsZscore } = getThresholds(testConfig, test);

  const zscoreConvert = Number(zscore);
  const pathoThresholdAsZscoreConvert = Number(pathoThresholdAsZscore);
  const difficultyThresholdAsZscoreConvert = Number(difficultyThresholdAsZscore);

  if (zscoreConvert > Math.abs(pathoThresholdAsZscoreConvert)) {
    return (zscoreConvert / Math.abs(pathoThresholdAsZscoreConvert)) * Math.abs(NORMALIZED_PATHO_ZSCORE);
  }
  if (zscoreConvert > Math.abs(difficultyThresholdAsZscoreConvert)) {
    // above abs(difficultyThresholdAsZscore)
    return (
      Math.abs(NORMALIZED_DIFF_ZSCORE) +
      ((Math.abs(zscoreConvert) - Math.abs(difficultyThresholdAsZscoreConvert)) /
        Math.abs(pathoThresholdAsZscoreConvert - difficultyThresholdAsZscoreConvert)) *
        Math.abs(NORMALIZED_PATHO_ZSCORE - NORMALIZED_DIFF_ZSCORE)
    );
  }
  if (zscoreConvert > 0) {
    // between 0 and abs(difficultyThresholdAsZscore)
    return (zscoreConvert / difficultyThresholdAsZscoreConvert) * NORMALIZED_DIFF_ZSCORE;
  }
  if (zscoreConvert > difficultyThresholdAsZscoreConvert) {
    // between difficultyThresholdAsZscore and 0
    return (zscoreConvert / difficultyThresholdAsZscoreConvert) * NORMALIZED_DIFF_ZSCORE;
  }
  if (zscoreConvert > pathoThresholdAsZscoreConvert) {
    // between pathoThresholdAsZscore and difficultyThresholdAsZscore
    return (
      NORMALIZED_DIFF_ZSCORE -
      (Math.abs(zscoreConvert - difficultyThresholdAsZscoreConvert) /
        Math.abs(pathoThresholdAsZscoreConvert - difficultyThresholdAsZscoreConvert)) *
        Math.abs(NORMALIZED_PATHO_ZSCORE - NORMALIZED_DIFF_ZSCORE)
    );
  }
  return (zscoreConvert / pathoThresholdAsZscoreConvert) * NORMALIZED_PATHO_ZSCORE;
};

export default getNormalizedZScore;
