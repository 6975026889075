import React from "react";
import { InputAdornment, Tooltip } from "@material-ui/core";
import { Alarm } from "@material-ui/icons";
import labels from "../../../../../constants/labels/patient/test/TestFillingHeader.json";
import { inputIncludesScoreET } from "../../testUtils/calculateValue/ScoreOrScoreStandardDeviationValue";
import { inputIncludesNS19 } from "../../testUtils/calculateValue/NS19Value";
import { inputIncludesTempsET } from "../../testUtils/calculateValue/TimeOrTimeStandardDeviationValue";
import {
  inputIncludesErrorScoreStandardDeviation
} from "../../testUtils/calculateValue/ErrorScoreStandardDeviationValue";
import { inputIncludesErrorTimeStandardDeviation } from "../../testUtils/calculateValue/ErrorTimeStandardDevitaiton";
import standardDeviationError from "./StandardDeviationError";

function scoreStandardDeviation({input, columns, id, testResult, fireTab}) {
  return {
    displayCondition: inputIncludesScoreET(input) || inputIncludesNS19(input),
    replacementCondition: !!((columns.SCORE_ET || columns.NS19) && !inputIncludesTempsET(input)),
    id: "SCORE_ET|NS19",
    placeholder: "-",
    controlName: `${id}.scoreET`,
    value: testResult.scoreET,
    fullWidth: true,
    inputProps: { inputProps: { step: "0.01", onKeyPress: fireTab } },
    disabled: testResult.$$DISABLETYPING || inputIncludesNS19(input)
  };
}

function timeStandardDeviation({input, columns, id, testResult, fireTab}) {
  return {
    displayCondition: inputIncludesTempsET(input),
    replacementCondition: !!(
      columns.TEMPS_ET &&
      !(inputIncludesScoreET(input) || inputIncludesNS19(input)) &&
      !((columns.SCORE_ET || columns.NS19) && !inputIncludesTempsET(input))
    ),
    id: "TEMPS_ET",
    placeholder: "-",
    controlName: `${id}.tempsET`,
    value: testResult.tempsET,
    fullWidth: true,
    inputProps: {
      inputProps: { step: "0.01", onKeyPress: fireTab },
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip title={labels.tooltips.standardDeviationTime}>
            <Alarm />
          </Tooltip>
        </InputAdornment>
      )
    }
  };
}

const standardDeviation = ({ input = [], columns = {}, id, testResult = {}, fireTab }) => ({
  header: {
    condition: !!(columns.SCORE_ET || columns.NS19 || columns.TEMPS_ET || columns.ERR_SCORE_ET || columns.ERR_TEMPS_ET),
    label: labels.resultColumnTitles.standardDeviation
  },
  column: {
    score: inputIncludesErrorScoreStandardDeviation(input) ? standardDeviationError({input, columns, id, testResult, fireTab}).column.score : scoreStandardDeviation({input, columns, id, testResult, fireTab}),
    time: inputIncludesErrorTimeStandardDeviation(input) ? standardDeviationError({input, columns, id, testResult, fireTab}).column.time : timeStandardDeviation({input, columns, id, testResult, fireTab})
  }
});

export default standardDeviation;
