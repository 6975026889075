import { getLocation } from './exalangCommonImport';
// import normalizeImporterClasse from './NormalizeImporterClasse';
//
// const iconv = require('iconv-lite');

const MAPPING = [
  ['custom00'], // 0: Empan visuel
  ['custom_2'], // 1: Empan endroit
  ['custom_3'], // 2: Empan auditif envers
  ['custom_4', 'custom_5'], // 3: Flexibilité lexicale (score), Flexibilité lexicale (temps)
  ['custom_6', 'custom_7'], // 4:Repérage d'anaphores (score), Repérage d'anaphores (temps)
  ['custom_8'], // 5: Consignes orales
  ['custom_10', 'custom_11'], // 6: Inférences -  score, Inférences - temps
  ['custom_12'], // 7: Lecture de mots
  ['custom_13', 'custom_14'], // 8: Lecture de logatomes - score, Lecture de logatomes - temps
  ['custom_16', 'custom_15'], // 9: Leximétrie - mots lus correctement, Leximétrie - note de temps pondérée
  ['custom_17', 'custom_18', 'custom_27'], // 10: Compréhension de texte -  temps de lecture silencieuse, Compréhension de texte - temps moyen/retour
  ['custom_19', 'custom_20', 'custom_29'], // 11: Repérage - score, Repérage - temps, Repérage (ratio)
  ['custom_21', 'custom_28', 'custom_22'], // 12: Texte à choix multiple (lexique), Texte à choix multiple - score grammaire, Texte à choix multiple - temps
  ['custom_25', 'custom_24'], // 13: Phrases à compléter - score lexique, Phrases à compléter - score grammaire
  ['custom_30', 'custom_31'] // 14: Orthographe lexicale, Orthographe grammatical
];

// returns an object with following keys :
// count : number of row in passation that can be imported
// values : an object with entry key the test name and value the properties of the test result
// calculOption : the calculation table to be used after import
// calculOptionType: string, 'age' or 'classe' depending on calculation table key
const executeImport = ({ data }) => {
  const passationTestAnswersAsArray = [...data.getElementsByTagName('Test')];
  const values = passationTestAnswersAsArray
    .map((testRow, index) => ({ testRow, index }))
    .filter(({ testRow }) => testRow.getAttribute('Effectue') === 'oui')
    .flatMap(({ testRow, index: testRowIndex }) =>
      MAPPING[testRowIndex].map((eprCode, eprIndex) => ({
        [eprCode]: { score: parseFloat(testRow.getAttribute('Reponse').split(';')[eprIndex]) }
      }))
    )
    .reduce((acc, obj) => {
      const [key, value] = Object.entries(obj).shift();
      acc[key] = value;
      return acc;
    }, {});

  if (
    passationTestAnswersAsArray[12].getAttribute('Effectue') === 'oui' &&
    passationTestAnswersAsArray[13].getAttribute('Effectue') === 'oui'
  ) {
    const [response12a, response12b] = passationTestAnswersAsArray[12]
      .getAttribute('Reponse')
      .split(';')
      .map(x => parseFloat(x));
    const [response13a, response13b] = passationTestAnswersAsArray[13]
      .getAttribute('Reponse')
      .split(';')
      .map(x => parseFloat(x));
    if (response12a && response13a) {
      values[MAPPING[14][0]] = { score: response12a + response13a };
    }
    if (response12b && response13b) {
      values[MAPPING[14][1]] = { score: response12b + response13b };
    }
  }

  return { count: Object.keys(values).length, values, calculOption: 'Adulte' };
};

const parseFile = (filePath, encoding) => {
  // TODO uncomment for happyneuron import
  // if (!fs.existsSync(filePath)) {
  //   return;
  // }
  // const xmlContent = fs.readFileSync(filePath);
  // const decodedContent = iconv.decode(xmlContent, encoding || 'win1252');
  // const oDOM = new window.DOMParser().parseFromString(decodedContent, 'application/xml');
  // const fiches = oDOM.getElementsByTagName('fiches')[0];
  //
  // return [...fiches.getElementsByTagName('profil')].flatMap(profilElement =>
  //   [...profilElement.getElementsByTagName('passation')].map(passation => ({
  //     firstname: profilElement.getAttribute('prenom'),
  //     lastname: profilElement.getAttribute('nom'),
  //     birthDate: profilElement.getAttribute('datenaissance'),
  //     classe: profilElement.getAttribute('className'),
  //     passationNumber: passation.getAttribute('numero'),
  //     passationDate: passation.getAttribute('datepassage'),
  //     cotationCode: normalizeImporterClasse(profilElement.getAttribute('classe')),
  //     hnWorkoutId: passation.getAttribute('workoutId'),
  //     data: passation
  //   }))
  // );
};

const ExalangLyfacImport = () => ({ getLocation, parseFile, executeImport });
export default ExalangLyfacImport;
