import moment from 'moment/moment';
import appVersionSpecific from '../appVersionSpecific.ortho';
import MyStore from '../actions/MyStore';
import { fetchJson } from '../components/common/OrthoscribeWebSite';
import { hnParamsDownload } from '../components/patient/import/utils/HappyneuronFileDownloader';

export function isUserLegitOrthoscribe(license) {
  if (!license || !license.expiresUnixTimestamp) return false;
  const now = moment();
  const { expiresUnixTimestamp } = license;
  const orthoscribeExpires = moment.unix(expiresUnixTimestamp + 3600 * 24);
  return orthoscribeExpires.isAfter(now);
}

export function getLicense() {
  const expiresUnixTimestamp = MyStore.get(appVersionSpecific.electronStoreLicenseDate);
  return { expiresUnixTimestamp };
}

export function isHappyNeuronImport({ticket, userId}) {
  const isHappyNeuronImport = !!ticket && !!userId;
  return { isHappyNeuronImport };
}

export async function isUserLegitHappyNeuron(query) {
  const now = moment();
  const ticket = query.get("ticket");
  const userId = query.get("userId");

  const { isHappyNeuronImport: happyNeuronImport } = isHappyNeuronImport({ticket, userId});
  if (!happyNeuronImport) {
    return { legitHappyNeuron: false };
  }

  try {
    const hnJsonContent = await hnParamsDownload({ticket, userId});

    if (hnJsonContent && hnJsonContent.therapist && hnJsonContent.therapist.expiration) {
      const happyNeuronExpirationUnix = moment(hnJsonContent.therapist.expiration);
      console.log({ happyNeuronExpirationUnix, now });
      const legitHappyNeuron = happyNeuronExpirationUnix.isAfter(now);
      return { legitHappyNeuron, hnJsonContent };
    }
  } catch (e) {
    console.error(e)
    return { legitHappyNeuron: false };
  }
  return { legitHappyNeuron: false };
}

export async function isUserLegit({license, query}) {
  const { legitHappyNeuron, hnJsonContent } = await isUserLegitHappyNeuron(query);
  const mLicense = license || getLicense();

  if (!mLicense) {
    console.log('No licence');
    console.log({ legitHappyNeuron });
    return { isUserLegit: legitHappyNeuron, legitHappyNeuron, hnJsonContent }
  }

  const legitOrthoscribe = isUserLegitOrthoscribe(mLicense);
  console.log({ legitOrthoscribe, legitHappyNeuron });
  const isUserLegit = legitOrthoscribe || legitHappyNeuron;
  return { isUserLegit, legitOrthoscribe, legitHappyNeuron, hnJsonContent };
}
