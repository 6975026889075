import { findZone, getZoneColor, getZones } from './Zone';
import getNormalizedZScore from '../../../../constants/tests/ScoreEvaluator';

const getZoneColorFromZScore = async (options, zscore, testCode) => {
  const normalizedZScore = await getNormalizedZScore(testCode, zscore);
  const zones = getZones(options);
  const zone = findZone(normalizedZScore, zones);
  console.log({ zscore, normalizedZScore, zones, zone });
  return getZoneColor(zone, options);
};

export default getZoneColorFromZScore;
