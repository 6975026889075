// import DEFAULT_HN_CLASSES from '../DEFAULT_HN_CLASSES.json';
// import happyneuronExalangClassToOrthoscribeClasse from '../HappyneuronExalangClassToOrthoscribeClasse';

const parseFile = filePath => {
  // TODO uncomment for happyneuron import
  // if (!fs.existsSync(filePath)) {
  //   return;
  // }
  // const jsonContent = fs.readFileSync(filePath, 'UTF-8');
  // const emilie = JSON.parse(jsonContent);
  // const { code: classe } =
  // happyneuronExalangClassToOrthoscribeClasse(DEFAULT_HN_CLASSES, Number(emilie.user.class)) || {};
  //
  // return emilie.workouts.map(workout => ({
  //   firstname: emilie.user.firstName,
  //   lastname: emilie.user.lastName,
  //   birthDate: emilie.user.birthDate,
  //   passationDate: workout.startDate,
  //   passationNumber: workout.id,
  //   data: { workout },
  //   hnWorkoutId: workout.id,
  //   classe,
  //   text: `${emilie.user.firstName} ${emilie.user.lastName} (${workout.startDate})`
  // }));
};

export default parseFile;
