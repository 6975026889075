import MathUtil from "../../../common/Math";
import getNormalizedZScore from "../../../../constants/tests/ScoreEvaluator";
import getZoneColorFromZScore from "./GetZoneColorFromZScore";


const getZoneColorFromCentile = async (options, score, testCode) => {
  const zscore = await getNormalizedZScore(testCode, MathUtil.percentileToStdDeviation(score));
  return getZoneColorFromZScore(options, zscore);
}

export default getZoneColorFromCentile;
