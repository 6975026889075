const getFilteredPercentileTableByMaxPercentile = (pctTable) => {
  const valueCount = pctTable.reduce((acc, {v}) => ({...acc, [v]: acc[v] ? acc[v] + 1 : 1}), {})
  return pctTable.filter(({p, v}) => {
    if (valueCount[v] > 1) {
      // case pctTable has duplicate by value, we remove duplicates if their percentile is not the max of the value
      return pctTable.filter(({v: v2}) => v === v2).sort(({p:a}, {p:b}) => b-a).pop().p === p;
    }
    // case pctTable has no duplicate for this value, we keep the value
    return true;
  });
};

export default getFilteredPercentileTableByMaxPercentile;
