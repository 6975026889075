import _ from 'lodash';
import * as Tests from '../../../constants/tests/tests';
import { allLabeledTests, testByTestCode } from '../../../constants/tests/tests';
import CustomTests from '../../common/data/CustomTests';
import getPercentileValue from './testUtils/GetPercentileValue';
import calculateValues from './testUtils/CalculateValues';
import shouldIgnoreOnClassConstraint from './testUtils/ShouldIgnoreOnClassConstraint';
import currentTestHasCorrespondingCalculationTable from './testUtils/CurrentTestHasCorrespondingCalculationTable';
import canCompute from './testUtils/CanCompute';
import buildValues from './testUtils/BuildValues';
import updateCurrentTestResultsFromCalculOption from './testUtils/UpdateCurrentTestResultsFromCalculOption';
import updateCurrentTestResultsWithImportValues from './testUtils/UpdateCurrentTestResultsWithImportValues';
import erasePreviousResults from './testUtils/ErasePreviousResults';
import { inputIncludesErrorScoreStandardDeviation } from "./testUtils/calculateValue/ErrorScoreStandardDeviationValue";
import { inputIncludesCentileKey } from "./testUtils/calculateValue/CentileValue";
import { inputIncludesErrorCentile } from "./testUtils/calculateValue/ErrorCentileValue";
import { inputIncludesErrorTempsCentile } from "./testUtils/calculateValue/ErrorTempsCentileValue";
import { inputIncludesNS19 } from "./testUtils/calculateValue/NS19Value";
import { inputIncludeScoreAD } from "./testUtils/calculateValue/ScoreADValue";
import {
  inputIncludesScore,
  inputIncludesScoreET
} from "./testUtils/calculateValue/ScoreOrScoreStandardDeviationValue";
import { inputIncludesTempsCentile } from "./testUtils/calculateValue/TimeCentileValue";
import { inputIncludesTempsET } from "./testUtils/calculateValue/TimeOrTimeStandardDeviationValue";
import { inputIncludesQuali } from "./testUtils/calculateValue/QualiValue";

const getValueIfNumberOrUndefined = value => (!Number.isNaN(value) ? value : undefined);

function getTestValues(customTests, testCode, cotation) {
  const allTests = [...allLabeledTests, ...customTests];
  const currentTest = testByTestCode(allTests, testCode);
  const currentTestResults = cotation.results[testCode] || {};
  currentTestResults.$$calculOption =
    currentTest.calcul && currentTest.calcul.length === 1
      ? currentTest.calcul[0].id
      : currentTestResults.$$calculOption;
  const {
    examinations,
    calculationData,
    columns,
    disableCentileColumn,
    currentTestHasCalculOptions,
    displayCalculatedPercentile
  } = buildValues(currentTest, currentTestResults.$$calculOption);
  currentTestResults.$$displayRawScore =
    typeof currentTestResults.$$displayRawScore === 'boolean' && !currentTestResults.$$displayRawScore
      ? !!currentTestHasCalculOptions
      : true;
  examinations.forEach(({ id }) => {
    const ignore = shouldIgnoreOnClassConstraint(currentTest, currentTestResults);
    // recalculus of currentTestResults
    // debugger;
    const currentTestChildren = currentTest.children.find(({ id: childId }) => childId === id) || { input: [] };
    const notHiddenFromView = calculationData
      ? canCompute(calculationData, id) || inputIncludesQuali(currentTestChildren.input) || !ignore
      : inputIncludesQuali(currentTestChildren.input) || !ignore;
    currentTestResults[id] = _.merge(currentTestResults[id] || {}, {
      $$HIDDENFROMVIEW: !notHiddenFromView,
      $$DISABLETYPING: (calculationData && currentTest.disableTyping) || false,
      $$displayRawScore:
        typeof currentTestResults.$$displayRawScore === 'boolean' && !currentTestResults.$$displayRawScore
          ? !!currentTestHasCalculOptions
          : true,
      ignore
    });
    if (inputIncludesScore(currentTestChildren.input) || currentTest.$$displayRawScore) {
      currentTestResults[id].score = getValueIfNumberOrUndefined(currentTestResults[id].score);
    }
    if (inputIncludesCentileKey(currentTestChildren.input)) {
      currentTestResults[id].scoreCentile = getValueIfNumberOrUndefined(currentTestResults[id].scoreCentile);
      currentTestResults[id].inScoreCentile = true;
    }
    if (inputIncludesScoreET(currentTestChildren.input)) {
      currentTestResults[id].scoreET = getValueIfNumberOrUndefined(currentTestResults[id].scoreET);
      currentTestResults[id].inScoreET = true;
    }
    if (inputIncludesTempsET(currentTestChildren.input)) {
      currentTestResults[id].tempsET = getValueIfNumberOrUndefined(currentTestResults[id].tempsET);
      currentTestResults[id].inTempsET = true;
    }
    if (inputIncludesTempsCentile(currentTestChildren.input)) {
      currentTestResults[id].tempsCentile = getValueIfNumberOrUndefined(currentTestResults[id].tempsCentile);
      currentTestResults[id].inTempsCentile = true;
    }
    if (inputIncludeScoreAD(currentTestChildren.input)) {
      currentTestResults[id].scoreAD = getValueIfNumberOrUndefined(currentTestResults[id].scoreAD);
      currentTestResults[id].inScoreAD = true;
    }
    if (inputIncludesErrorScoreStandardDeviation(currentTestChildren.input)) {
      currentTestResults[id].errScoreET = getValueIfNumberOrUndefined(
        currentTestResults[id].errScoreET || currentTestResults[id].scoreET
      );
      currentTestResults[id].inErrScoreET = true;
    }
    if (inputIncludesErrorCentile(currentTestChildren.input)) {
      currentTestResults[id].errCentile = getValueIfNumberOrUndefined(currentTestResults[id].errCentile);
      currentTestResults[id].inErrCentile = true;
    }
    if (inputIncludesNS19(currentTestChildren.input)) {
      currentTestResults[id].ns = getValueIfNumberOrUndefined(currentTestResults[id].ns);
      currentTestResults[id].inNs = true;
    }
    if (inputIncludesErrorTempsCentile(currentTestChildren.input)) {
      currentTestResults[id].errTempsCentile = getValueIfNumberOrUndefined(currentTestResults[id].errTempsCentile);
      currentTestResults[id].inErrTempsCentile = true;
    }
  });
  return {
    currentTest,
    currentTestResults,
    examinations,
    calculationData,
    columns,
    disableCentileColumn,
    currentTestHasCalculOptions,
    displayCalculatedPercentile
  };
}

const shouldHideRow = (hideNonFilledTests, currentTestResults) => ({ id }) =>
  hideNonFilledTests ? isQuestionAnsweredOrCommented(currentTestResults[id]) : true;

const valueIsZeroOrDefined = value => value === 0 || (value !== '' && value);

const isQuestionAnsweredOrCommented = testResult =>
  testResult &&
  (valueIsZeroOrDefined(testResult.score) ||
    ((testResult.inNs || testResult.inScoreET) && valueIsZeroOrDefined(testResult.scoreET)) ||
    (testResult.inErrScoreET && valueIsZeroOrDefined(testResult.errScoreET)) ||
    (testResult.inScoreCentile && valueIsZeroOrDefined(testResult.scoreCentile)) ||
    (testResult.inTempsET && valueIsZeroOrDefined(testResult.tempsET)) ||
    (testResult.inTempsCentile && valueIsZeroOrDefined(testResult.tempsCentile)) ||
    (testResult.inErrCentile && valueIsZeroOrDefined(testResult.errCentile)) ||
    (testResult.inErrTempsCentile && valueIsZeroOrDefined(testResult.errTempsCentile)) ||
    (testResult.inScoreAD && valueIsZeroOrDefined(testResult.scoreAD)) ||
    !!testResult.comment);

const listAnsweredTests = results =>
  Object.entries(results).flatMap(([testCode, currentTestResults]) =>
    Object.entries(currentTestResults)
      .filter(([, currentTestResult]) => isQuestionAnsweredOrCommented(currentTestResult))
      .map(([id]) => ({ testCode, id }))
  );

const listNonAffectedAnsweredTests = async (elements, answeredTests) => {
  const usedInModelTestsList = elements.flatMap(({ tests }) => tests);
  const customTestsFromDb = await CustomTests.findAll();

  const displayableTests = [...Tests.allLabeledTests, ...customTestsFromDb];

  return answeredTests
    .filter(
      ({ testCode, id }) => !usedInModelTestsList.find(uimt => uimt && uimt.testCode === testCode && uimt.id === id)
    )
    .map(({ id, testCode }) => {
      const test = testByTestCode(displayableTests, testCode);
      const child = (test.children || []).filter(({ id: cId }) => cId === id);
      const { label } = child[0] || {};
      return { id: label, testLabel: test.label };
    })
    .filter(({ id, testLabel }) => testLabel && id);
};

function isExalang(testCode) {
  return /EXALANG.+/gi.test(testCode) || /EXAMATH.+/gi.test(testCode);
}

function isExamath58(testCode) {
  return /EXAMATH-5-8.+/gi.test(testCode);
}

const bilanIsExalangOnly = ({ tests }) => (tests || []).reduce((acc, curr) => acc && isExalang(curr.testCode), true);
const bilanHasExalang = ({ tests }) => (tests || []).reduce((acc, curr) => acc || isExalang(curr.testCode), false);

const bilanIsExamath58Only = ({ tests }) =>
  (tests || []).reduce((acc, curr) => acc && isExamath58(curr.testCode), true);
const bilanHasExamath58 = ({ tests }) => (tests || []).reduce((acc, curr) => acc || isExamath58(curr.testCode), false);

async function listDisplayableBilanTests(selectedTestCodes, customTestsProvided) {
  const customTests = await getCustomTests(customTestsProvided);
  const displayableTests = [...Tests.allLabeledTests, ...customTests].filter(
    ({ testCode, uuid, id, _id }) =>
      selectedTestCodes.includes(testCode) ||
      selectedTestCodes.includes(uuid) ||
      selectedTestCodes.includes(id) ||
      selectedTestCodes.includes(_id)
  );
  return displayableTests;
}

async function getCustomTests(customTestsProvided) {
  if (customTestsProvided) return customTestsProvided;
  return CustomTests.findAll();
}

function listBilanDoneTestCountByTestCode(displayableTests, results) {
  const doneTestCount = displayableTests
    .map(({ testCode }) => {
      const currentTest = results[testCode];
      return {
        testCode,
        count: currentTest ? Object.values(currentTest).filter(isQuestionAnsweredOrCommented).length : 0
      };
    })
    .reduce((acc, { testCode, count }) => {
      acc[testCode] = count;
      return acc;
    }, {});
  return doneTestCount;
}

export {
  buildValues,
  canCompute,
  shouldIgnoreOnClassConstraint,
  getPercentileValue,
  calculateValues,
  updateCurrentTestResultsFromCalculOption,
  updateCurrentTestResultsWithImportValues,
  currentTestHasCorrespondingCalculationTable,
  erasePreviousResults,
  getTestValues,
  shouldHideRow,
  isQuestionAnsweredOrCommented,
  listAnsweredTests,
  listNonAffectedAnsweredTests,
  isExalang,
  bilanHasExalang,
  bilanIsExalangOnly,
  bilanIsExamath58Only,
  bilanHasExamath58,
  listDisplayableBilanTests,
  listBilanDoneTestCountByTestCode
};
