import {
  isErrCentile,
  isErrScoreET,
  isErrTempsCentile,
  isNSOrScoreET,
  isScoreCentile,
  isTempsCentile,
  isTempsET
} from '../../../../constants/tests/TestResult';
import getZoneColorFromZScore from './GetZoneColorFromZScore';
import getZoneColorFromCentile from './GetZoneColorFromCentile';

const fromZscore = zscore => zscore * 42.99226; // -2.326ET = C1 < x < 2.326ET = C99 | 100/2.326 = 42.99
const fromCentile = centile => (centile - 50) * 2;
const fromErrCentile = errCentile => (50 - errCentile) * 2;
const isPercentileObjectValid = po => po && (po.percentileValue || po.value);
const limitValue = value => {
  if (value > 100) return 100;
  if (value < -100) return -100;
  if (value < 5 && value > -5) return 5;
  return value;
};
const getWidth = value => Math.abs(value);

const transformFunctions = [
  {
    id: 'isNSOrScoreET',
    matchFn: isNSOrScoreET,
    getValueTransformFn: fromZscore,
    getValueFn: tr => tr.scoreET,
    getColorTransformFn: getZoneColorFromZScore,
    getColorValueFn: tr => tr.scoreET
  },
  {
    id: 'isTempsET',
    matchFn: isTempsET,
    getValueTransformFn: fromZscore,
    getValueFn: tr => tr.tempsET,
    getColorTransformFn: getZoneColorFromZScore,
    getColorValueFn: tr => tr.tempsET
  },
  {
    id: 'isErrScoreET',
    matchFn: isErrScoreET,
    getValueTransformFn: fromZscore,
    getValueFn: tr => tr.errScoreET,
    getColorTransformFn: getZoneColorFromZScore,
    getColorValueFn: tr => tr.errScoreET
  },
  {
    id: 'isScoreCentile',
    matchFn: isScoreCentile,
    getValueTransformFn: fromCentile,
    getValueFn: tr => tr.scoreCentile,
    getColorTransformFn: getZoneColorFromCentile,
    getColorValueFn: tr => tr.scoreCentile
  },
  {
    id: 'isTempsCentile',
    matchFn: isTempsCentile,
    getValueTransformFn: fromCentile,
    getValueFn: tr => tr.tempsCentile,
    getColorTransformFn: getZoneColorFromCentile,
    getColorValueFn: tr => tr.tempsCentile
  },
  {
    id: 'isErrCentile',
    matchFn: isErrCentile,
    getValueTransformFn: fromErrCentile,
    getValueFn: tr => tr.errCentile,
    getColorTransformFn: getZoneColorFromCentile,
    getColorValueFn: tr => 100 - tr.errCentile
  },
  {
    id: 'isErrTempsCentile',
    matchFn: isErrTempsCentile,
    getValueTransformFn: fromCentile,
    getValueFn: tr => tr.errTempsCentile,
    getColorTransformFn: getZoneColorFromCentile,
    getColorValueFn: tr => 100 - tr.errTempsCentile
  }
];

const getColor = async (value, testResult, options, testCode) => {
  const transformFunction = transformFunctions.find(({ matchFn }) => matchFn(testResult));
  if (transformFunction) {
    return transformFunction.getColorTransformFn(options, transformFunction.getColorValueFn(testResult), testCode);
  }
  return value >= 0 ? 'lightgreen' : 'red';
};

const getValue = (percentileObject, testResult) => {
  const transformFunction = transformFunctions.find(({ matchFn }) => matchFn(testResult));
  if (transformFunction) {
    return limitValue(transformFunction.getValueTransformFn(transformFunction.getValueFn(testResult)));
  }

  if (isPercentileObjectValid(percentileObject)) {
    return limitValue(fromCentile(percentileObject.percentileValue || percentileObject.value));
  }
  return NaN;
};

const CotationBarUtil = { getValue, getColor, getWidth };
export default CotationBarUtil;
