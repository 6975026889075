import {
  findAscending,
  findDescending,
  findAscendingBest,
  findByMiddle,
  findDescendingBest,
  findIndexOfPc50OrClosest,
  findTimeDescendingBest
} from '../PercentileCalculator';
import { calculateExalangAscending, calculateExalangDescending } from '../percentileCalculator/ExalangPercentileCalculator';
import calculateExamath58Ascending from "../percentileCalculator/Examath58AscendingPercentileCalculator";
import calculateExamath58Descending from "../percentileCalculator/Examath58DescendingPercentileCalculator";
import calculateExamath58PAE from "../percentileCalculator/Examath58PAEPercentileCalculator";

const getPercentileValue = ({ value, pctTable, order, indexOfpc50 }) => {
  if (order === 'best-ascending') {
    return findAscendingBest(value, pctTable);
  }
  if (order === 'best-descending') {
    return findDescendingBest(value, pctTable);
  }
  if (order === 'best-time-descending') {
    return findTimeDescendingBest(value, pctTable);
  }
  if (order === 'middle' && indexOfpc50 !== undefined) {
    return findByMiddle(value, pctTable, indexOfpc50);
  }
  if (order === 'middle' && indexOfpc50 === undefined) {
    return findByMiddle(value, pctTable, findIndexOfPc50OrClosest(pctTable));
  }
  if (order === 'exalang-ascending') {
    return calculateExalangAscending({ value, pctTable });
  }
  if (order === 'exalang-descending') {
    return calculateExalangDescending({ value, pctTable });
  }
  if (order === 'examath58-ascending') {
    return calculateExamath58Ascending({ value, pctTable });
  }
  if (order === 'examath58-descending') {
    return calculateExamath58Descending({ value, pctTable });
  }
  if (order === 'examath58-PAE') {
    return calculateExamath58PAE({ value, pctTable });
  }
  if (order.toUpperCase() === 'ASC') {
    return findAscending(value, pctTable);
  }
  return findDescending(value, pctTable);
};

export default getPercentileValue;
