import ALOUETTER from './ALOUETTER.json';
import BALE from './BALE.json';
import BDAE from './BDAE.json';
import BELO from './BELO.json';
import BILOC3 from './BILOC3.json';
import BLI from './BLI.json';
import ECLA16 from './ECLA16+.json';
import ECLA16PC from './ECLA16+_PC.json';
import ECLCOLLEGE from './ECL COLLEGE.json';
import ECOSSELO from './ECOSSELO.json';
import ELO from './ELO.json';
import ELO_PC from './ELO_PC.json';
import ELO_ET from './ELO_ET.json';
import ECS_II from './ECS-II.json';
import EFCL from './EFCL.json';
import EVADYS from './EVADYS.json';
import EVALAD from './EVALAD.json';
import EVALADFIXED from './EVALADFIXED.json';
import EVALADFIXED_CALC from './EVALADFIXED_CALC.json';
import EVALEC from './EVALEC.json';
import EXALANG_3_6 from './EXALANG 3-6.json';
import EXALANG_5_8 from './EXALANG 5-8.json';
import EXALANG_8_11 from './EXALANG 8-11.json';
import EXALANG_11_15 from './EXALANG 11-15.json';
import EXALANG_LYFAC from './EXALANG-LYFAC.json';
import EXAMATH_5_8 from './EXAMATH-5-8.json';
import EXAMATH_8_15 from './EXAMATH-8-15.json';
import FIGUREDEREY from './FIGURE DE REY.json';
import KODJO1 from './KODJO1.json';
import KODJO2 from './KODJO2.json';
import L2MA from './L2-MA.json';
import LABALEINE from './LABALEINE.json';
import LMCR from './LMCR.json';
import LMCR_ET from './LMCR_ET.json';
import LMCR_PC from './LMCR_PC.json';
import MAXENCE from './MAXENCE.json';
import NEELFORMEG from './NEEL forme G.json';
import NEELFORMEP from './NEEL forme P.json';
import O_52 from './O52.json';
import ODEDYS from './ODEDYS.json';
import PHONOLECCOLLEGE from './PHONOLECCOLLEGE.json';
import PREDIFEX from './PREDIFEX.json';
import PREDILEM from './PREDILEM.json';
import PREDIMEM from './PREDIMEM.json';
import PREDILAC from './PREDILAC.json';
import PROTOCOLEMILIE from './PROTOCOLEMILIE.json';
import PROTOCOLEMILIEHAPPYNEURON from './PROTOCOLEMILIEHAPPYNEURON.json';
import TCG from './TCG.json';
import VITESSEENLECTURE from './VITESSEENLECTURE.json';
import CustomTests from '../../components/common/data/CustomTests';
import { prepareTestForDisplay } from './PrepareTestForDisplay';
import appVersionSpecific from '../../appVersionSpecific.ortho';

const isHappyNeuron = appVersionSpecific.isHappyNeuron;

const ortho = isHappyNeuron
  ? [
      EXALANG_3_6,
      EXALANG_5_8,
      EXALANG_8_11,
      EXALANG_11_15,
      EXALANG_LYFAC,
      EXAMATH_5_8,
      EXAMATH_8_15,
      PREDIFEX,
      PREDILEM,
      PREDILAC,
      PREDIMEM,
      EVALEC,
      EVADYS,
      PROTOCOLEMILIEHAPPYNEURON
    ]
  : [
      ALOUETTER,
      BALE,
      BDAE,
      BELO,
      BILOC3,
      BLI,
      ECLA16,
      ECLA16PC,
      ECLCOLLEGE,
      ECOSSELO,
      ELO,
      ELO_PC,
      ELO_ET,
      ECS_II,
      EFCL,
      EVADYS,
      EVALAD,
      EVALADFIXED,
      EVALEC,
      EXALANG_3_6,
      EXALANG_5_8,
      EXALANG_8_11,
      EXALANG_11_15,
      EXALANG_LYFAC,
      EXAMATH_5_8,
      EXAMATH_8_15,
      FIGUREDEREY,
      KODJO1,
      KODJO2,
      L2MA,
      LABALEINE,
      LMCR,
      LMCR_ET,
      LMCR_PC,
      MAXENCE,
      NEELFORMEG,
      NEELFORMEP,
      O_52,
      ODEDYS,
      PHONOLECCOLLEGE,
      PREDIFEX,
      PREDILEM,
      PREDILAC,
      PREDIMEM,
      PROTOCOLEMILIE,
      PROTOCOLEMILIEHAPPYNEURON,
      TCG,
      VITESSEENLECTURE
    ];

export const includedTests = {
  ortho,
  happy: ortho
}[process.env.APP_SPEC || 'ortho'];

const orthoIncludedTestsByName = {
  ALOUETTER,
  BALE,
  BDAE,
  BELO,
  BILOC3,
  BLI,
  ECLA16,
  ECLA16PC,
  ECLCOLLEGE,
  ECOSSELO,
  ELO,
  ELO_PC,
  ELO_ET,
  'ECS-II': ECS_II,
  EFCL,
  EVADYS,
  EVALAD,
  EVALADFIXED,
  EVALADFIXED_CALC,
  EVALEC,
  'EXALANG-3-6': EXALANG_3_6,
  'EXALANG-5-8': EXALANG_5_8,
  'EXALANG-8-11': EXALANG_8_11,
  'EXALANG-11-15': EXALANG_11_15,
  'EXALANG-LYFAC': EXALANG_LYFAC,
  'EXAMATH-5-8': EXAMATH_5_8,
  'EXAMATH-8-15': EXAMATH_8_15,
  FIGUREDEREY,
  KODJO1,
  KODJO2,
  L2MA,
  LABALEINE,
  LMCR,
  LMCR_ET,
  LMCR_PC,
  MAXENCE,
  NEELFORMEG,
  NEELFORMEP,
  'O-52': O_52,
  ODEDYS,
  PHONOLECCOLLEGE,
  PREDIFEX,
  PREDILEM,
  PREDILAC,
  PREDIMEM,
  PROTOCOLEMILIE,
  PROTOCOLEMILIEHAPPYNEURON,
  TCG,
  VITESSEENLECTURE
};

export const includedTestsByName = {
  ortho: orthoIncludedTestsByName,
  happy: orthoIncludedTestsByName
}[process.env.APP_SPEC || 'ortho'];

export const allLabeledTests = includedTests.map(prepareTestForDisplay);

export const filteredLabeledTests = allLabeledTests.filter(({ available }) => available !== false);

export const testByTestCode = (testList, testCode) => testList.filter(test => test.testCode === testCode).shift() || {};

// export default { includedTests, filteredLabeledTests, testByTestCode };

export const getTestByTestCode = async testCode => {
  const test = testByTestCode(includedTests, testCode);
  if (test) {
    return prepareTestForDisplay(test);
  }
  const customTest = await CustomTests.retrieveCustomTestsById(testCode);
  if (customTest) {
    return prepareTestForDisplay(customTest);
  }
};
