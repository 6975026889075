// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import Pagination from '@material-ui/lab/Pagination';
import Patient from '../common/data/Patient';
import AppConfig from '../common/data/AppConfig';
import log from '../Logger';
import appVersionSpecific from '../../appVersionSpecific.ortho';
import HomeStyle from '../../constants/styles/HomeStyle.json';
import HomePatientListHeader from './HomePatientListHeader';
import HomeHeader from './HomeHeader';
import HomePatientList from './HomePatientList';
import HomeMultiActionButton from './HomeMultiActionButton';
import MyStore from "../../actions/MyStore";
import AnamnesisResponses from '../common/data/AnamnesisResponses';
import DeveloperModeHomeExtraButtons from './DeveloperModeHomeExtraButtons';

const useStyles = makeStyles(HomeStyle);

const ORDER_DESC = 'desc';
const ORDER_ASC = 'asc';
const Home = () => {
  const testFillingRowCompactDisplay = MyStore.getAsBoolean('testFillingRowCompactDisplay', false);
  const savedPageSize = MyStore.get('pageSize', 5);

  const [patientBase, setPatientBase] = useState([]);
  const [archived, setArchived] = useState(false);
  const [archivedPatients, setArchivedPatients] = useState([]);
  const [q, setQ] = useState('');
  const [sortField, setSortField] = useState('modificationDate');
  const [sortOrder, setSortOrder] = useState(ORDER_DESC);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(savedPageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [multiAction, setMultiAction] = useState(false);
  const [multiActionSelectedPatientList, setMultiActionSelectedPatientList] = useState([]);
  const [anamnesisResponses, setAnamnesisResponses] = useState([]);

  const classes = useStyles({ testFillingRowCompactDisplay });
  const history = useHistory();

  const loadPatients = async (_archived, _q, _sortField, _sortOrder, _pageNumber, _pageSize) => {
    try {
      const { totalLength, patients } = await Patient.getPatientList(
        _archived,
        _q,
        _sortField,
        _sortOrder,
        _pageNumber,
        _pageSize
      );
      return { totalLength, patients };
    } catch (e) {
      log.error('Error retrieving patientBase', e);
      throw e;
    }
  };

  const refreshList = useCallback(async () => {
    const { totalLength, patients } = await loadPatients(archived, q, sortField, sortOrder, pageNumber, pageSize);
    if (totalLength === 0) {
      const { totalLength: totalArchivedLength, patients: myArchivedPatients } = await loadPatients(true, q);
      await setArchivedPatients(myArchivedPatients);
      setPatientBase([]);
      setTotalCount(totalArchivedLength);
      return;
    }
    const responses = await AnamnesisResponses.listResponsesOnDocumentStorage();
    setAnamnesisResponses(responses);
    setPatientBase(patients);
    setTotalCount(totalLength);
  }, [archived, pageNumber, pageSize, q, sortField, sortOrder]);


  useEffect(() => {
    const loadOptions = async () => {

      const { val } = await AppConfig.retrieveValueFromDb({ keyValue: 'opt-main-order' });
      if (!val) {
        return;
      }
      const [field, order] = val.split('_');
      setSortField(field);
      setSortOrder(order);
    };

    loadOptions().then();
  }, []);

  useEffect(() => {
    refreshList().then();
  }, [archived, q, sortField, sortOrder, pageNumber, pageSize, refreshList]);


  const saveOptions = async ({ field, order }) => {
    await AppConfig.save({ keyValue: 'opt-main-order', value: `${field}_${order}` });
  };

  const sortBy = field => async () => {
    if (sortField === field && sortOrder === ORDER_DESC) {
      await setSortOrder(ORDER_DESC);
      await setSortField('modificationDate');
      await saveOptions({ field: 'modificationDate', order: ORDER_DESC });
    } else if (sortField === field) {
      const order = sortOrder === ORDER_ASC ? ORDER_DESC : ORDER_ASC;
      await setSortOrder(order);
      await saveOptions({ field, order });
    } else {
      await setSortOrder(ORDER_ASC);
      await setSortField(field);
      await saveOptions({ field, order: ORDER_ASC });
    }
  };

  const searchTextChange = event => {
    setQ(event.target.value);
    setPageNumber(1);
  };

  const goToPatient = id => async () => {
    history.push(appVersionSpecific.mainPagePatientLink(id));
  };

  const displayEmptyDatabase = () => patientBase.length === 0 && !q && !archived && archivedPatients.length === 0;
  const pageCount = Math.ceil(totalCount / pageSize);

  return (
    <Grid container alignItems="stretch" spacing={3}>
      {displayEmptyDatabase() && (
        <Grid item md={12} container alignItems="center" justifyContent="center">
          <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
              {appVersionSpecific.homePage.noPatient.mainTitle}
            </Typography>
            <Typography variant="subtitle1">{appVersionSpecific.homePage.noPatient.subTitle}</Typography>
          </Grid>
        </Grid>
      )}
      <DeveloperModeHomeExtraButtons q={q} />
      {!displayEmptyDatabase() && (
        <React.Fragment>
          <HomeHeader
            q={q}
            searchChange={searchTextChange}
            multiAction={multiAction}
            setMultiAction={setMultiAction}
            archived={archived}
            setArchived={setArchived}
          />
          <HomePatientListHeader sortField={sortField} sortOrder={sortOrder} sortBy={sortBy} />
        </React.Fragment>
      )}
      <HomePatientList
        patientBase={patientBase}
        testFillingRowCompactDisplay={testFillingRowCompactDisplay}
        multiAction={multiAction}
        multiActionSelectedPatientList={multiActionSelectedPatientList}
        setMultiActionSelectedPatientList={setMultiActionSelectedPatientList}
        goToPatient={goToPatient}
        refreshList={refreshList}
        anamnesisResponses={anamnesisResponses}
      />
      {!displayEmptyDatabase() && pageCount > 1 && (
        <Grid item md={12} container alignItems="center" justifyContent="center">
          <Pagination
            component="div"
            count={pageCount}
            page={pageNumber}
            className={classes.root}
            onChange={(e, newPage) => setPageNumber(newPage)}
          />
        </Grid>
      )}
      {multiAction && (
        <HomeMultiActionButton
          archived={archived}
          multiActionSelectedPatientList={multiActionSelectedPatientList}
          refreshList={async () => {
            await setMultiActionSelectedPatientList([]);
            refreshList();
          }}
        />
      )}
    </Grid>
  );
};

export default Home;
