import moment from 'moment';
import exalang36Import from './exalang36Import';
import exalangImport from './exalangImport';
import exalangLyfac from './exalangLyfacImport';
import examathImport from './examathImport';
import DEFAULT_HN_CLASSES from './DEFAULT_HN_CLASSES.json';
import happyneuronExalangClassToOrthoscribeClasse from './HappyneuronExalangClassToOrthoscribeClasse';

const Importers = encoding => ({
  'EXALANG-3-6': exalang36Import(encoding),
  'EXALANG-5-8': exalangImport(encoding),
  'EXALANG-8-11': exalangImport(encoding),
  'EXALANG-11-15': exalangImport(encoding),
  'EXALANG-LYFAC': exalangLyfac(encoding),
  'EXAMATH-8-15': examathImport(encoding)
});
export default Importers;

export const createPatientFromPassation = ({ data, ...choice }, hnParamsFileContent) => {
  const hnPatientId = hnParamsFileContent.patient.userId;
  console.log({ choice, data, hnParamsFileContent });
  const birthDate = choice.birthDate ? moment(choice.birthDate, 'DD/MM/YYYY') : null;

  let sexe: 'F' | 'H' = 'H';
  if (hnParamsFileContent && hnParamsFileContent.patient && hnParamsFileContent.patient.civilite !== undefined) {
    sexe = hnParamsFileContent.patient.civilite === 1 ? 'F' : 'H';
  }

  if (choice.testCode.indexOf('EXALANG') === 0) {
    return {
      firstName: choice.firstname,
      lastName: choice.lastname,
      sexe,
      birthDay: birthDate,
      meta: { hn: { uid: hnPatientId } },
      curclasse: happyneuronExalangClassToOrthoscribeClasseId(hnParamsFileContent.classes, choice.classe).toString(10)
    };
  }
  if (choice.testCode.indexOf('EXAMATH') === 0) {
    return {
      firstName: choice.firstname,
      lastName: choice.lastname,
      sexe,
      birthDay: birthDate,
      meta: { hn: { uid: hnPatientId } },
      curclasse: undefined
    };
  }
  if (['PREDILAC', 'PREDILEM', 'PREDIFEX', 'PREDIMEM'].includes(choice.testCode)) {
    return {
      firstName: choice.firstname,
      lastName: choice.lastname,
      sexe: choice.fullData.patient.gender === '1' ? 'H' : 'F',
      birthDay: choice.fullData.patient.birthDate ? moment(choice.fullData.patient.birthDate, 'DD/MM/YYYY') : null,
      meta: { hn: { uid: hnPatientId } },
      curclasse: undefined
    };
  }

  return null;
};

export const happyneuronExalangClassToOrthoscribeClasseId = (
  hnClasses = DEFAULT_HN_CLASSES,
  classcode: string | number
): number => {
  const { id } = happyneuronExalangClassToOrthoscribeClasse(hnClasses, classcode);
  return id;
};
